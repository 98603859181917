.rbt{
    .dropdown-item{
        display: block;
        width: 100%;
        padding: .25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        &:hover{
            color: #16181b;
            text-decoration: none;
            background-color: #f8f9fa;
        }
        &:active, &.active{
            color: #fff;
            text-decoration: none;
            background-color: #007bff;
        }
    }
}