/*
 *  Document: bootstrap_colorpicker.less
 *
 *  Bootstrap Colorpicker
 *
 */

.colorpicker-saturation {
    float: left;
    width: 100px;
    height: 100px;
    cursor: crosshair;
    background-image: url("@{assets_folder}/jquery.colorpicker/saturation.png");
}

.colorpicker-saturation i {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 5px;
    height: 5px;
    margin: -4px 0 0 -4px;
    border: 1px solid #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.colorpicker-saturation i b {
    display: block;
    width: 5px;
    height: 5px;
    border: 1px solid #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.colorpicker-hue,
.colorpicker-alpha {
    float: left;
    width: 15px;
    height: 100px;
    margin-bottom: 4px;
    margin-left: 4px;
    cursor: row-resize;
}

.colorpicker-hue i,
.colorpicker-alpha i {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    margin-top: -1px;
    background: #000;
    border-top: 1px solid #fff;
}

.colorpicker-hue {
    background-image: url("@{assets_folder}/jquery.colorpicker/hue.png");
}

.colorpicker-alpha {
    display: none;
    background-image: url("@{assets_folder}/jquery.colorpicker/alpha.png");
}

.colorpicker-saturation,
.colorpicker-hue,
.colorpicker-alpha {
    background-size: contain;
}

.colorpicker {
    top: 0;
    left: 0;
    z-index: 2500;
    min-width: 130px;
    padding: 4px;
    margin-top: 1px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.colorpicker:before,
.colorpicker:after {
    display: table;
    line-height: 0;
    content: "";
}

.colorpicker:after {
    clear: both;
}

.colorpicker:before {
    position: absolute;
    top: -7px;
    left: 6px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.colorpicker:after {
    position: absolute;
    top: -6px;
    left: 7px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}

.colorpicker div {
    position: relative;
}

.colorpicker.colorpicker-with-alpha {
    min-width: 140px;
}

.colorpicker.colorpicker-with-alpha .colorpicker-alpha {
    display: block;
}

.colorpicker-color {
    height: 10px;
    margin-top: 5px;
    clear: both;
    background-image: url("@{assets_folder}/jquery.colorpicker/alpha.png");
    background-position: 0 100%;
}

.colorpicker-color div {
    height: 10px;
}

.colorpicker-selectors {
    display: none;
    height: 10px;
    margin-top: 5px;
    clear: both;
}

.colorpicker-selectors i {
    cursor: pointer;
    float: left;
    height: 10px;
    width: 10px;
}

.colorpicker-selectors i + i {
    margin-left: 3px;
}

.colorpicker-element .input-group-addon i,
.colorpicker-element .add-on i {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    cursor: pointer;
}

.colorpicker.colorpicker-inline {
    position: relative;
    z-index: auto;
    display: inline-block;
    float: none;
}

.colorpicker.colorpicker-horizontal {
    width: 110px;
    height: auto;
    min-width: 110px;
}

.colorpicker.colorpicker-horizontal .colorpicker-saturation {
    margin-bottom: 4px;
}

.colorpicker.colorpicker-horizontal .colorpicker-color {
    width: 100px;
}

.colorpicker.colorpicker-horizontal .colorpicker-hue,
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
    float: left;
    width: 100px;
    height: 15px;
    margin-bottom: 4px;
    margin-left: 0;
    cursor: col-resize;
}

.colorpicker.colorpicker-horizontal .colorpicker-hue i,
.colorpicker.colorpicker-horizontal .colorpicker-alpha i {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1px;
    height: 15px;
    margin-top: 0;
    background: #ffffff;
    border: none;
}

.colorpicker.colorpicker-horizontal .colorpicker-hue {
    background-image: url("@{assets_folder}/jquery.colorpicker/hue-horizontal.png");
}

.colorpicker.colorpicker-horizontal .colorpicker-alpha {
    background-image: url("@{assets_folder}/jquery.colorpicker/alpha-horizontal.png");
}

.colorpicker.colorpicker-hidden {
    display: none;
}

.colorpicker.colorpicker-visible {
    display: block;
}

.colorpicker-inline.colorpicker-visible {
    display: inline-block;
}

.colorpicker-right:before {
    left: auto;
    right: 6px;
}

.colorpicker-right:after {
    left: auto;
    right: 7px;
}

.colorpicker-no-arrow:before {
    border-right: 0;
    border-left: 0;
}

.colorpicker-no-arrow:after {
    border-right: 0;
    border-left: 0;
}

.colorpicker.colorpicker-visible,
.colorpicker-alpha.colorpicker-visible,
.colorpicker-saturation.colorpicker-visible,
.colorpicker-hue.colorpicker-visible,
.colorpicker-selectors.colorpicker-visible {
    display: block;
}

.colorpicker.colorpicker-hidden,
.colorpicker-alpha.colorpicker-hidden,
.colorpicker-saturation.colorpicker-hidden,
.colorpicker-hue.colorpicker-hidden,
.colorpicker-selectors.colorpicker-hidden {
    display: none;
}

.colorpicker-inline.colorpicker-visible {
    display: inline-block;
}

.colorpicker.dropdown-menu {
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
    -webkit-transition: none !important;
    transition: none !important;
    min-width: 0 !important;
}