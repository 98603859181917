/*
 *  Document: datatables.less
 *
 *  Datatables
 *
 */

.dataTables_wrapper > div:not(.react-bootstrap-table) {
    background-color: @color_light_ultra;
    padding: 8px 0 5px;
    width: auto;
    border: 1px solid @color_light_main;
    border-top-width: 0;
}

.dataTables_wrapper > div:not(.react-bootstrap-table):first-child {
    border-top-width: 1px;
    border-bottom-width: 0;
}
.react-bootstrap-table{
    background-color: #fff;
}
.dataTables_wrapper .row {
    margin: 0;
}

.dataTables_filter label,
.dataTables_length label,
.dataTables_info,
.dataTables_paginate {
    margin: 0;
    padding: 0;
}

.dataTables_filter label {
    font-weight: normal;
    float: left;
    text-align: left;
}

div.dataTables_length select {
    width: 75px;
}

div.dataTables_filter label {
    font-weight: normal;
    float: right;
}

div.dataTables_filter input {
    width: 150px;
}

.dataTables_info {
    padding-top: 8px;
}

.dataTables_paginate {
    float: right;
    margin: 0;
}

div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
}

table.dataTable td,
table.dataTable th,
.react-bootstrap-table .table td,
.react-bootstrap-table .table th{
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

table.dataTable, .react-bootstrap-table .table {
    clear: both;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    max-width: none !important;
}

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    padding-right: 20px;
}

.table thead .sorting,
.table thead .sorting_asc,
.table thead .sorting_desc,
.table thead .sorting_asc_disabled,
.table thead .sorting_desc_disabled {
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 19px 19px;
}

.table thead .sorting { background-image: url('@{assets_folder}/jquery.datatables/sort_both.png'); }
.table thead .sorting_asc { background-image: url('@{assets_folder}/jquery.datatables/sort_asc.png'); }
.table thead .sorting_desc { background-image: url('@{assets_folder}/jquery.datatables/sort_desc.png'); }
.table thead .sorting_asc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_asc_disabled.png'); }
.table thead .sorting_desc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_desc_disabled.png'); }

table.dataTable thead > tr > th, .react-bootstrap-table table thead > tr > th {
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: super;
    font-size: 13px;
}

.react-bootstrap-table table th:active {
    outline: none;
}

div.dataTables_scrollHead table {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
    border-top: none;
}

div.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {

    .table thead .sorting { background-image: url('@{assets_folder}/jquery.datatables/sort_both@2x.png'); }
    .table thead .sorting_asc { background-image: url('@{assets_folder}/jquery.datatables/sort_asc@2x.png'); }
    .table thead .sorting_desc { background-image: url('@{assets_folder}/jquery.datatables/sort_desc@2x.png'); }
    .table thead .sorting_asc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_asc_disabled@2x.png'); }
    .table thead .sorting_desc_disabled { background-image: url('@{assets_folder}/jquery.datatables/sort_desc_disabled@2x.png'); }
}
.react-bootstrap-table .filter-label{
    display: block;
    margin-top: 10px;
}
.react-bootstrap-table-page-btns-ul:extend(.pagination-sm){
    text-align: center;
}
.react-bootstrap-table-page-btns-ul>li:first-child>a:extend(.pagination-sm>li:first-child>a){
    text-align: center;
}
.react-bootstrap-table-page-btns-ul>li>a:extend(.pagination-sm>li>a){
    text-align: center;
}
.react-bootstrap-table .select-filter{
    max-width: 120px;
}
.react-bootstrap-table th{
    position: relative;
    & .caret{
        display: block;
        border-top: 6px dashed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }
    & .dropup .caret{
        border-top: 0;
        border-bottom: 6px dashed;            
        content: "";
    }
    & .order, & .react-bootstrap-table-sort-order{
        display: flex;
        position: absolute;
        width: 12px;
        height: 30px;
        right: 2px;
        top: 2px;
        flex-direction: column-reverse;
        justify-content: end;
        cursor: pointer;
        & .dropdown, & .dropup{
            height: 9px;
            color: #3942636b;
        }        
    }
    & .react-bootstrap-table-sort-order{
        height: 25px;
    }
}

.react-bs-table-sizePerPage-dropdown .btn,
.react-bs-table-sizePerPage-dropdown .btn:hover,
.react-bs-table-sizePerPage-dropdown .btn:active:hover,
.open.react-bs-table-sizePerPage-dropdown .btn.dropdown-toggle,
.open.react-bs-table-sizePerPage-dropdown .btn.dropdown-toggle:hover,
.open.react-bs-table-sizePerPage-dropdown .btn.dropdown-toggle:focus{
    background-color: #fff;
}
.tablehead{
    display: flex;
    flex-direction: column;
    min-height: 140px;
    justify-content: space-between;
}
.dataTables_wrapper .table .filter{
    display: none;
}
.react-bs-table-sizePerPage-dropdown{
    margin-right: 1rem;
}
.react-bootstrap-table .label{
    margin: 3px 3px 3px 0;
    display: inline-block;
    padding: 5px;
}
.react-bootstrap-table td b{
    font-weight: 700;
}
.selection-cell{
    text-align: center;
}
.selection-cell-header{
    width: 15px;
}
.editable__cell{
    padding-right: 18px;
    position: relative;
    &__icon{
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
}
